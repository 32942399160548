@use '../variables' as *;

.request-info-wrapper {
  .user-info-wrapper {
    .status-card {
      padding: 12px;
      border: 1px solid $salem;
      border-radius: 5px;
      background-color: rgba($color: $salem, $alpha: 0.1);

      &.requesting {
        background-color: rgba($color: $color-active, $alpha: 0.1);
        border-color: $color-active;
      }

      &.rejected,
      &.return_request {
        background-color: rgba($color: $color-light-red, $alpha: 0.1);
        border-color: $color-light-red;
      }

      &.draft {
        background-color: rgba($color: $color-grey, $alpha: 0.1);
        border-color: $color-grey;
      }
    }
  }

  .request-timeline {
    .flex-1 {
      flex: 1;
    }

    .text-blur {
      opacity: 0.6;
    }

    .timeline-section {
      min-height: 135px;

      .line-warpper {
        position: relative;

        .circle {
          display: block;
          background-color: #fff;
          height: 40px;
          width: 40px;
          border: 4px solid transparent;
          border-radius: 100%;
          display: grid;
          place-items: center;
          outline: 1px dashed $salem;

          mat-icon {
            scale: 0.85;
          }

          &.quit,
          &.rejected,
          &.return_request,
          &.suspended {
            outline-color: $color-light-red;
          }

          &.requesting,
          &.pending {
            outline-color: $sea-buckthorn;
          }

          &.draft {
            outline-color: $color-grey;

            mat-icon {
              scale: 1;
            }
          }
        }

        .horizontal-line {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;

          .line {
            border-left: 2px solid $salem;
            height: inherit;
            display: block;
          }
        }
      }

      .mobile-off {
        min-width: 12em;
      }

      .create_by {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__status {
          .mobile-on {
            display: none;
          }

          .timeline-title {
            font-size: 16px;
          }
        }

        &__quit-reason {
          width: fit-content;

          &__wrapper {
            background-color: $black-squeeze-light;
          }
        }
      }

      .reason-rejected,
      .reason-return_request,
      .reason-suspended {
        .create_by {
          &__quit-reason {
            &__wrapper {
              background-color: rgba($color: $color-light-red, $alpha: 0.1);
              span {
                color: $color-light-red;
              }
            }
          }
        }
      }

      .reason-requesting {
        .create_by {
          &__quit-reason {
            &__wrapper {
              background-color: rgba($color: $sea-buckthorn, $alpha: 0.1);
              span {
                color: $sea-buckthorn;
              }
            }
          }
        }
      }

      @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        .mobile-off {
          display: none;
        }

        .create_by {
          &__status {
            .mobile-on {
              display: block;
              width: fit-content;
            }
          }
        }
      }
    }
  }

  .attachment {
    .header-label {
      margin-bottom: 0;
    }

    .full-width {
      width: 100%;
    }

    .status-label {
      padding: 8px 12px;
      border-radius: 50px;

      &.done {
        color: $salem;
        background: rgba($color: $salem, $alpha: 0.1);
      }

      &.warning {
        color: $sea-buckthorn;
        background: rgba($color: $sea-buckthorn, $alpha: 0.1);
      }
    }

    &__form-wrapper {
      width: 100%;

      .card {
        border-top: 1px solid $border-grey;

        &:not(:last-child) {
          padding-block: 16px;
        }

        &:last-child {
          padding-top: 16px;
        }

        .color-blur {
          opacity: 0.6;
        }
      }

      .status-icon {
        &.color-primary {
          mat-icon {
            display: grid;
            place-items: center;
            background-color: #20c374;
            color: #fff;
            border-radius: 100%;
            font-size: 20px;
          }
        }

        mat-icon {
          width: 32px;
          height: 32px;
        }
      }

      .required-sign {
        &::after {
          content: ' *';
          color: $color-required;
        }
      }

      .group-btn {
        gap: 0;
      }
    }
  }

  .id-card-wrapper {
    border: 2px dashed #e6e6e6;
    overflow: hidden;
    border-radius: 10px;
    min-height: 205px;
    height: 100%;
    align-content: center;
    padding: 16px;

    .img-wrapper {
      width: 100%;
      height: 100%;
      display: grid;
      place-content: center;

      img {
        width: 285px;
        height: 175px;
        object-fit: cover;
      }

      .placeholder {
        object-fit: contain;
        padding: 3em;
      }
    }
  }
}
