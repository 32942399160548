@use '../variables' as *;
@use '../functions/pixel-to-rem' as f;

$bar-height: 70px;
$lg-width: 250px;
$sm-width: 84px;
$margin-top: 160px;

.app-menu {
  position: relative;

  mat-sidenav {
    width: $sm-width;
    background: $color-primary;
    border: none;

    @media screen and (min-width: 959px) {
      transition: width 0.2s ease;

      &.large-width {
        width: $lg-width;
      }
    }

    &:not(.large-width) {
      .main-logo {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }

    .main-logo {
      cursor: pointer;
      background-color: $color-primary;
      height: $margin-top;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      z-index: 1;
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      justify-content: center;
      align-items: center;

      img {
        transition: all 100ms linear;
        object-fit: cover;
        border-radius: 50%;
        margin: auto;
      }

      .logo {
        &.title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 8px;

          span {
            width: 20em;
            text-align: center;
            font-family: 'Moul', 'Khmer', sans-serif;
            line-height: normal;
            color: #fff;
            font-size: 12px;
          }

          .main-title {
            font-size: 16px;
          }

          .main-subtitle {
            font-size: 12.5px;
          }
        }
      }
    }

    mat-nav-list {
      overflow-x: hidden !important;
      overflow-y: auto !important;
      padding-top: unset !important;

      padding-top: 0 !important;
      padding-bottom: 0;
      margin-top: $margin-top;
      height: calc(100% - $margin-top);

      mat-list-item {
        margin-top: 0 !important;

        mat-icon {
          color: $scarpa-flow;
        }
      }
    }
  }

  .dashboard-toolbar {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $color-primary;
    z-index: 9999;
    position: absolute;
    padding: 0;
    height: auto;

    &.blurred {
      background-color: $color-primary;
    }

    .dashboard__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
      min-height: $bar-height;
      width: 100%;
      background-color: #fff;

      img {
        cursor: pointer;
      }

      h1 {
        margin: 0;
        padding: 0;
        font-weight: 400;
        flex: 1;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .icon {
        background-repeat: no-repeat;
        background-position: center;
        color: #fff;
      }
    }

    .header-action {
      display: flex;
      align-items: center;
      position: relative;
      column-gap: 8px;

      @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        justify-content: center;
        flex: 1;
      }

      .avatar {
        .profile-menu-btn {
          border-radius: $rounded-sm;
          padding: 4px;

          .mdc-button {
            &__label {
              line-height: 1;
            }
          }
        }
      }

      .vertical-line {
        height: 45px;
        width: 2px;
        background-color: $background-grey;
      }
    }

    .dash-row {
      display: block;
      width: 100%;
      overflow: auto;
    }

    .dash-row + .dash-row {
      margin: 0rem 1rem;
    }
  }

  .mat-form-field-flex {
    width: 100%;
  }

  .no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding-right: 50px !important;
  }

  .mat-dialog-container {
    background: #f8f9fe;
    padding: 50px 80px !important;

    .mat-dialog-actions {
      button.mat-focus-indicator.mat-button.mat-button-base.mat-primary {
        color: #59595b;
      }

      button {
        background: #fff;
        padding: 5px 25px;
      }
    }
  }

  .app-container {
    height: 100dvh;
    background: $black-squeeze-light;

    .example-sidenav {
      /* width */
      ::-webkit-scrollbar {
        width: 0px !important;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888 !important;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
  }

  #list-nav {
    margin-top: 160px;

    .menu-item-title {
      color: #fff;
    }

    mat-icon {
      color: #fff;
    }

    .active {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .mat-mdc-list-item.mat-mdc-list-item-single-line,
    .mat-mdc-list-option.mat-mdc-list-item-single-line {
      height: 55px !important;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .mat-mdc-list-item {
      color: $scarpa-flow;
      border-radius: $rounded-md;

      &.rounded-0 {
        border-radius: 0;
      }

      .mat-mdc-list-item-unscoped-content {
        width: 100%;

        .menu-item-title {
          padding-left: 20px;
          line-height: normal;
          font-weight: 400;
          padding-left: 6px;
        }
      }

      &.triangle {
        .mat-mdc-list-item-unscoped-content {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          mat-icon {
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }

      &:hover {
        &:not(.active) {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }
    }

    //*Custom child nav style
    .mat-expansion-panel {
      .mat-expansion-panel-body {
        background-color: transparent;
        padding: 0px !important;

        mat-icon {
          color: $color-secondary;
        }

        .mat-list-item {
          height: 50px !important;
          font-size: 14px;
        }

        .mat-nav-list {
          .mat-list-item {
            &.no-expand {
              .mat-list-item-content {
                height: 100%;
                flex-direction: row;
                box-sizing: border-box;
                display: flex;
                place-content: center;
                align-items: center;

                span {
                  display: none;
                }

                mat-icon {
                  padding: 0;
                }
              }
            }
          }
        }

        .expand {
          .mat-badge {
            position: relative;
            top: 9px;
            left: 12px;
          }

          .mdc-list-item__content {
            overflow: visible;
            margin-left: 2em;
          }
        }
      }
    }

    .mat-expansion-panel {
      background-color: transparent;

      &.parents {
        .mat-nav-list {
          .mat-list-item {
            &.active {
              position: relative;

              &::after {
                top: 10%;
              }
            }
          }
        }
      }

      .mat-mdc-nav-list {
        margin: 0;

        mat-icon {
          width: 20px;
          height: 20px;
          font-size: 18px;
        }

        .mat-mdc-list-item {
          span {
            font-size: 14px;
          }
        }
      }

      .mat-expansion-panel-header {
        height: 55px !important;
        display: flex;
        padding: 0 16px;
        font-size: 16px;
        border: none !important;

        &.active {
          transition: background 0.3s linear;

          &:not([aria-expanded='false']) {
            background-color: transparent;

            .mat-expansion-indicator {
              &::after {
                color: #fff !important;
              }
            }
          }

          &:is([aria-expanded='false']) {
            background-color: rgba($color: #000000, $alpha: 0.1);
          }
        }

        .mat-content {
          align-items: center;

          .mat-icon {
            margin-right: 10px;
            width: 24px;
            min-width: 20px;
          }
        }

        .mat-expansion-indicator {
          display: flex;
          margin-right: 2px;

          &::after {
            color: #fff !important;
          }
        }
      }
    }

    .mat-list-text {
      display: none !important;
    }

    // notification
    .mat-icon {
      &.dots::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 16px;
        left: 34px;
        background: #ff5d5d;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transition: transform 200ms ease-in-out;
        transform: scale(0.6);
      }
    }
  }
}

.hover-profile + * .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    border-radius: $rounded-sm;

    .profile-img {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: initial;
      min-width: 12em;
      height: 70px;
      min-height: 70px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: transparent !important;
      }

      .avatar-profile {
        width: f.rem(48);
        height: f.rem(48);
      }

      .name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .role {
        font-size: 12px;
        color: $scarpa-flow;
      }

      &:hover {
        background-color: $black-squeeze-color;
      }
    }
  }
}

.nav-mat-menu + * .cdk-overlay-pane {
  margin-left: 6em;

  .mat-mdc-menu-panel {
    overflow-x: hidden;
    border-radius: $rounded-sm;
    mat-nav-list {
      padding: 0;
      .active {
        background-color: rgba($color: $color-primary, $alpha: 1);
        .menu-item-title {
          color: #fff;
        }
      }
      .menu-item-title {
        font-size: 14px;
        border-radius: $rounded-md;
      }
    }
  }
}

// .mat-mdc-menu-panel {
//   min-width: 185px !important;

//   .mdc-list-item__content {
//     overflow: visible;
//   }

//   .mat-badge {
//     position: relative;
//     top: 10px;
//     left: 12px;
//   }
// }
