@use '../variables' as *;
@use '../mixin/background' as *;
@use '../mixin/flex' as *;

.login-wrapper {
  .full-height {
    min-height: 100vh;
  }

  .p-v-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-h-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .flex-center {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }

  .card-container {
    width: 100%;
    max-width: 466px;
  }

  .w-100 {
    width: 100%;
  }

  .m-h-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .bg {
    background-image: url('/assets/imgs/login-background.png');
    @include bg(center center);
  }

  .card {
    position: relative;
    background-color: #fff;
    margin-bottom: 1.25rem;
    border: 1px solid #edf2f9;
    border-radius: 0.25rem;

    .card-body {
      padding: 1.5rem;
      position: relative;

      .flex-between {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
      }

      img {
        width: 65px;
      }
      .label {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgb(77 84 124 / 9%) !important;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .text-muted {
    color: #6c757d !important;
  }

  .font-size-13 {
    font-size: 13px !important;
  }
}
