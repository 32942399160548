mat-tab-group {
  mat-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          background-color: #fff;
          width: fit-content;
          border-radius: 50px;
          border: 1px solid #0000000D;
          padding: 1px;

          &:hover {
            border-radius: 50px;
          }

          .mdc-tab {
            &__ripple {
              &::before {
                border-radius: 50px;
              }
            }
            .mat-ripple {
              border-radius: 50px;
            }
          }

          .mdc-tab--active {
            .mdc-tab__text-label {
              color: #fff;
            }
            .mdc-tab-indicator {
              .mdc-tab-indicator {
                &__content {
                  border-top-width: 48px;
                  border-radius: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
