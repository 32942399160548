@use '../variables';

.creation-form,
.detail-form {
  background-color: variables.$color-white;
  padding: variables.$spacing-md;
  border-radius: variables.$rounded-sm;

  label {
    color: variables.$color-label;
  }

  mat-form-field {
    margin-top: 4px;
  }

  .header-label {
    color: variables.$color-primary;
    margin-bottom: variables.$spacing-md;
    font-size: 16px;
    font-weight: 700;
  }

  .section-label {
    color: variables.$color-primary;
    margin-bottom: variables.$spacing-md;
    font-size: 14px;
    font-weight: bold;
  }

  .border-bottom {
    border-bottom: 1px solid rgb(204, 204, 204);
    margin: 16px 0;
  }

  .group-action {
    text-align-last: end;
  }

  .value {
    color: variables.$scarpa-flow;
    font-weight: 600;
    width: 100%;

    &.name {
      font-size: 22px;

      .position {
        color: variables.$color-primary;
        font-weight: 500;
      }
    }
  }

  .fake-input {
    min-height: 44px;
    border-radius: variables.$rounded-sm;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-inline: 16px;

    /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide the scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      display: flex;
      align-items: center;
    }

    &.border {
      border: 1px solid variables.$black-squeeze-color;
    }

    .flex-1 {
      flex: 1;
    }
  }

  .fake-chip {
    background-color: #0000000d;
    padding: 10px 15px;
    border-radius: 50px;
    color: #00000080;
  }

  .invalid {
    color: variables.$color-required;
  }

  .required::after {
    content: '*';
    color: variables.$color-required;
  }

  hr {
    border-bottom: 1px solid #ddd;
    margin: 16px 0;
  }
}
