@use '../variables' as *;

/* custom mat table */
table.mat-mdc-table {
  width: 100%;
  border-radius: $rounded-md;

  tr {
    th {
      font-size: 14px;
      text-transform: capitalize;
      color: $color-label;
      font-weight: 400;
    }

    th,
    td {
      padding: 0 $spacing-sm;
      border-bottom-color: $black-squeeze-color;

      .mat-mdc-radio-group {
        display: flex;
        padding: 0;
      }
    }

    td {
      color: #000;
      border-bottom-color: transparent;
    }

    &.mat-mdc-row:last-child {
      td {
        border-bottom-color: $border-grey;
      }
    }
  }
}

//for tables which have white background
.mat-table-bg-white {
  &.with-border-none {
    .table-container {
      table {
        &.mat-mdc-table {
          tr {
            th {
              border-bottom-color: transparent;
            }
          }
        }
      }
    }
  }

  .table-container {
    border: 1px solid #d2d6dc66;
  }

  .filter-wrapper {
    .search-box,
    .trigger-btn {
      background-color: $background-light-grey;
    }

    .section-box {
      .filter-button {
        background-color: $background-light-grey;
      }
    }
  }
}
