@use '../variables' as *;

.mat-mdc-checkbox {
  &:not(.mdc-checkbox--disabled) {
    &.mat-base {
      .mdc-form-field {
        .mdc-checkbox {
          &__background {
            border-color: $color-checkbox !important;

          }
        }
      }
    }

    &.mat-primary {
      &.mat-mdc-checkbox-checked {
        .mdc-form-field {
          .mdc-checkbox {
            &__background {
              border-color: $color-primary !important;
              background-color: $color-primary !important;

              svg {
                color: #fff !important;
              }
            }

            &__ripple {
              background-color: $color-primary !important;
            }
          }
        }
      }

      .mdc-form-field {
        .mdc-checkbox {
          &__background {
            border-color: $color-checkbox !important;
          }

          .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
            background-color: $color-primary !important;
            border-color: $color-primary !important;
          }
        }
      }
    }

    &.mat-warn {
      &.mat-mdc-checkbox-checked {
        .mdc-form-field {
          .mdc-checkbox {
            &__background {
              border-color: $color-light-red !important;
              background-color: $color-light-red !important;

              svg {
                color: #fff !important;
              }
            }

            &__ripple {
              background-color: $color-light-red !important;
            }
          }
        }
      }

      .mdc-form-field {
        .mdc-checkbox {
          &__background {
            border-color: $color-light-red !important;
          }
        }
      }
    }

    &.mat-success {
      &.mat-mdc-checkbox-checked {
        .mdc-form-field {
          .mdc-checkbox {
            &__background {
              border-color: $salem !important;
              background-color: $salem !important;

              svg {
                color: #fff !important;
              }
            }

            &__ripple {
              background-color: $salem !important;
            }
          }
        }
      }
    }
  }

  .mdc-form-field {
    .mdc-checkbox {
      &__background {
        border-radius: 5px;
        border-width: thin;
        scale: 1.2;

        svg {
          padding: 3px;
        }
      }

      &__mixedmark {
        margin: 3px;
      }

      &__ripple {
        border-radius: 5px;
        border-width: thin;
        background-color: transparent !important;
      }
    }
  }

  &.ng-invalid {
    &.ng-touched {
      .mdc-checkbox {
        &__background {
          border-color: $color-light-red !important;
        }
      }

      label {
        color: $color-light-red;
      }
    }
  }
}
