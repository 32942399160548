.text-gradient-animate {
  background: linear-gradient(to right, #117ec1 20%, #97c2ff 30%, #4a96ff 70%, #117ec1 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: gradientAnimation 3s ease-in-out infinite alternate;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
}
