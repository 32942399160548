@use '../variables' as var;

.import-student {
  .file-name {
    font-size: 20px;
    color: var.$color-primary;
  }

  .content-uploader-box {
    height: 300px;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    border: 2px dashed rgba(#000, 0.1);
    padding: 16px;
    background-color: var.$alabaster;

    &.fileover {
      border: 2px dashed var.$color-primary;
    }

    .file-content {
      position: relative;

      &:hover {
        .clear-btn {
          display: block;
        }
      }

      .clear-btn {
        display: none;
        position: absolute;
        right: calc(100% - (50% + 60px));
        top: -8px;
        background-color: var.$color-required;
        color: white;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid;
        box-sizing: content-box;
      }
    }

    .content-thumbnail {
      width: 100%;
      height: 110px;
      background-position: center;
      background-repeat: no-repeat;

      margin: {
        left: auto;
        right: auto;
      }

      &.file {
        width: 110px;
        cursor: pointer;
      }
    }

    .browse-file {
      cursor: pointer;
    }

    &.content-required {
      border-color: red;
    }

    .file-name {
      font-weight: bold;
    }
  }

  custom-table {
    tr {
      position: relative;

      .error {
        color: red !important;

        &::after {
          position: absolute;
          content: '';
          background-color: rgba(red, 0.05);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 5px;
        }
      }
    }
  }

  button .mat-progress-spinner circle,
  button .mat-spinner circle {
    stroke: #fff !important;
  }

  button mat-spinner {
    margin-left: 5px;
    display: inline-block !important;
  }

  .dot {
    &::before {
      content: '\2022';
      font-weight: bold;
      display: inline-block;
      width: 10px;
      margin-left: -4px;
    }

    &#{&}--fail::before {
      color: red;
    }

    &#{&}--success::before {
      color: green;
    }
  }

  .failure-messages {
    color: red;
  }

  .error-row {
    background-color: rgba(red, 0.14);
  }
}

.app-service-form {
  mat-checkbox {
    &.mdc-checkbox--disabled {
      &.mat-mdc-checkbox-checked {
        .mdc-checkbox {
          &__background {
            background-color: var(--mdc-checkbox-selected-icon-color) !important;
          }
        }

        label {
          color: var.$scarpa-flow !important;
          font-weight: 600 !important;
        }
      }
    }

    .mdc-checkbox {
      flex: 0 0 15px;
    }

    label {
      padding-left: 0;
    }
  }
}
