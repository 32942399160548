@use "../base/color" as *;

#setting-role-permission {
  &.role-permission {

    .margin-left {
      &__auto {
        margin-left: auto;
      }
    }

    .border {
      &__left {
        border-left: 1px solid $athens-gray-color;
      }

      &__right {
        border-right: 1px solid $athens-gray-color;
      }
    }

    .font-weight {
      &__500 {
        font-weight: 500;
      }
    }

    .enable-click {
      pointer-events: auto;
    }

    .height-inherit {
      height: inherit;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .expansion-wrapper {
      overflow-x: auto;

      .content-wrapper {
        min-width: 960px;
        overflow-x: auto;

        mat-accordion {

          &.prevent-click {
            pointer-events: none;
          }

          mat-expansion-panel {
            box-shadow: none;
            margin: 0 !important;
            border-radius: 0 !important;
            border-top: 1px solid $athens-gray-color;
            border-left: 1px solid $athens-gray-color;
            border-right: 1px solid $athens-gray-color;

            &.top-row {
              border-top-left-radius: 10px !important;
              border-top-right-radius: 10px !important;
            }

            &.bottom-row {
              border-bottom-left-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
              border-bottom: 1px solid $athens-gray-color;
            }

            .mdc-checkbox {
              .mdc-checkbox__native-control {
                &:enabled {

                  &:checked~.mdc-checkbox__background,
                  &:indeterminate~.mdc-checkbox__background {
                    border-color: #298ABF;
                    background-color: #298ABF;
                  }
                }
              }
            }

            mat-expansion-panel-header {
              padding: 0;
              height: 45px !important;

              &:hover {
                background-color: transparent;
              }

              &.mat-expanded {
                .mat-content {
                  .expand-header {
                    mat-panel-title {
                      mat-icon {
                        transform: rotate(180deg);
                        transition: transform 300ms linear;
                      }
                    }
                  }
                }
              }

              .mat-content {
                height: 100%;
                margin-right: 0 !important;

                .expand-header {
                  width: 100%;
                  height: 100%;
                  font-size: 14px;

                  mat-panel-title {
                    height: 100%;
                    margin-right: 0;
                    padding-inline: 16px;
                    background-color: $background-light-grey;

                    mat-icon {
                      margin-right: 0;
                      transition: transform 300ms linear;
                    }
                  }
                }
              }

              .mat-expansion-indicator {
                display: none;
              }
            }

            .mat-expansion-panel-content {
              .mat-expansion-panel-body {
                background-color: #fff;

                .panel-body-wrapper {
                  min-height: 3em;
                  border-top: 1px solid $athens-gray-color;
                  place-content: center;

                  .horizontal-line {
                    width: 1px;
                    height: 1.8em;
                    display: block;
                    background: $mercury-color;
                    transform: translateX(-8px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

