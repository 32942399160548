@use '../variables';

/* custom mat button */
button {
  &.mat-mdc-mini-fab {
    width: 30px;
    height: 30px;

    mat-icon {
      scale: 0.65;
    }
  }

  &.mdc-button {
    padding: 3px 22px !important;
    border-radius: variables.$rounded-2xl;
    height: 40px !important;

    &.mat-accent {
      .mdc-button {
        &__label {
          color: #fff;
        }
      }
    }
  }

  &.button-select {
    height: 48px !important;
    border-radius: 4px !important;

    .mdc-button__label {
      width: max-content;
    }
  }
}

.group-button {
  display: flex;
  gap: 8px;
  align-items: center;
}
