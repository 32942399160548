@use '../variables';

.home {
  .app-header {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          background-color: #fff !important;
        }
      }

      .mat-mdc-form-field-infix {
        input {
          position: relative;
          z-index: 1;
        }
      }

      &:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid).mdc-text-field {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          border-color: transparent !important;
        }
      }
    }
  }

  .dashboard-wrapper {
    .card-wrapper {
      .card {
        background-color: #fff;
        border-radius: 12px;
        height: 95px;
        overflow: hidden;

        .amount {
          font-size: 24px;
        }

        .icon {
          height: 100%;
          align-content: center;

          &__requesting {
            background-color: #99b2c6;
          }
          &__approved {
            background-color: #79d47b;
          }
          &__rejected {
            background-color: #dc0000;
          }
          &__deliver {
            background-color: #68624d;
          }

          mat-icon {
            scale: 1.3;
          }
        }
      }
    }

    .graph-wrapper {
      background-color: #fff;
      border-radius: 12px;
    }
  }
}
