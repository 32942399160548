@use '../variables';

// image display
.transparent .mat-mdc-dialog-container {
  background: transparent;
  box-shadow: none;
  position: relative;
  padding: 0px;
  border-radius: 0px;
  overflow: inherit !important;

  .mat-mdc-dialog-surface {
    background: transparent;
    box-shadow: none;
    overflow: inherit;

    .close-button {
      position: absolute;
      top: 0px;
      right: 0px;
      transform: translate(50%, -50%);
    }

    .close-icon {
      transition: 1s ease-in-out;
    }

    .close-icon:hover {
      transform: rotate(180deg);
    }
  }
}

.backdropBackground {
  background-color: #000000;
  opacity: 0.7 !important;
}

//custom dialog header
#mat-dialog-custom {
  .dialog-header-custom {
    background-color: variables.$color-primary;
    justify-content: space-between;

    h3 {
      margin-bottom: 0;
    }

    h1 {
      font-size: 18px !important;
      line-height: normal !important;
      margin-bottom: 0;
    }

    & > * {
      color: #fff;
    }
  }

  .detail-form, .creation-form {
    padding: 0;
  }

  mat-dialog-content {
    padding: 20px;
    padding-bottom: 0;
  }

  mat-dialog-actions {
    padding: 20px;
    padding-top: 0;
    float: inline-end;
  }
}
